import _ from 'lodash';
import orderService from '@/services/orderService';
import EcommOrder from '@/model/ecomOrder';
import Vue from 'vue';
import Order from '../../model/order';
import messageService from '../../services/messageService';
import MessageRecords from '../../model/messageRecord';

/* eslint-disable camelcase */
const state = {
  baseUrl: '/order',
  orders: [],
  downloadOrdersList: [],
  downloadMessageRecordList: [],
  customerOrders: [],
  fetchedCustomerName: null,
  fetchedCustomerAddress: null,
  pagination: {},
  order: null,
  loading: false,
  downloadAptReportList: [],
  storeAvailability: '',
  tempOrder: [],
  errorMessage: '',
  storeIdPinCode: {},
  ecomOrderList: [],
  downloadOrdersListOnline: [],
  stateCodeList: [],
  userData: {},
  addressSuggestions: []
};

const actions = {
  list({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    orderService
      .list({ query })
      .then(response => {
        commit('setOrders', { orders: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },

  listByCustomer({ dispatch, commit }, { customerMobile, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setCustomerOrders', { customerOrders: [], user: null });
    orderService
      .listByCustomer({ customerMobile })
      .then(response => {
        commit('setCustomerOrders', { customerOrders: response.data.orders, user: response.data.user });
      })
      .catch(e => {
        dispatch(
          'alert/error',
          {
            position: 'center',
            title: '',
            text: 'No previous order found for this customer.',
            showType: 'toast'
          },
          { root: true }
        );
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  getOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setOrder', { order: {} });

    orderService
      .getOne({ id })
      .then(response => {
        commit('setOrder', { order: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },

  postOne({ dispatch, commit }, { order, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    orderService
      .postOne({ order })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'bottom-end',
            title: '',
            text: 'New order has been added.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  upload({ dispatch, commit }, { data, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    orderService
      .upload({ data })
      .then(_response => {
        commit('finishRequest');
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            type: 'success',
            text: 'Orders uploaded successfully !!'
          },
          { root: true }
        );
        router.go();
      })
      .catch(e => {
        let errorMessage = '';
        if (e.response.data.data[0]) {
          errorMessage = e.response.data.data[0].msg;
        }
        commit('requestFailed');
        dispatch(
          'alert/error',
          {
            position: 'center',
            title: errorMessage,
            type: 'error',
            showType: 'dialog',
            text: 'Upload failed, remember to use only sample file provided and check the line number !!'
          },
          { root: true }
        );
        dispatch('common/handleServiceException', { e }, { root: true });
      });
    commit('finishRequest');
  },
  patchOne({ dispatch, commit }, { orderId, order, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    orderService
      .patchOne({ orderId, order })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'bottom-end',
            title: '',
            text: 'Order has been updated.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  deleteOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    orderService
      .deleteOne({ id })
      .then(_response => {
        dispatch('list', { router });
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Order has been deleted.'
          },
          { root: true }
        );
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  sendAcknowledgement({ dispatch, commit }, { mobile, type, id, router, query }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    messageService
      .sendMessage({ mobile, type, id })
      .then(_response => {
        dispatch('list', { router, query });
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Message has been sent!'
          },
          { root: true }
        );
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  async download({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setDownloadOrdersList', { downloadOrdersList: [], pagination: null });

    let stopDownload = false;
    const pageSize = 5000;
    const accData = [];

    for (let i = 0; !stopDownload;) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const response = await orderService.list({
          query: { ...query, page: i + 1, page_size: pageSize }
        });
        if (response.data.pagination.total_rows > 5000) {
          dispatch(
            'alert/success',
            {
              showType: 'toast',
              position: 'center',
              title: 'Preparing your download, please wait...'
            },
            { root: true }
          );
        }
        if (response.data.rows.length === 0) {
          stopDownload = true;
        }
        accData.push(...response.data.rows);
        i += 1;
      } catch (e) {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
        return;
      }
    }
    commit('setDownloadOrdersList', {
      downloadOrdersList: accData,
      pagination: null
    });
  },
  setDownloadListToEmpty({ commit }) {
    commit('setDownloadOrdersList', { downloadOrdersList: [] });
  },
  downloadMessageRecord({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setMessageRecordList', { downloadMessageRecordList: [] });

    messageService
      .list({ query })
      .then(response => {
        commit('setMessageRecordList', { downloadMessageRecordList: response.data.rows });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  setDownloadMessageRecordListEmpty({ commit }) {
    commit('setMessageRecordList', { downloadMessageRecordList: [] });
  },
  downloadAptReport({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setAptReportList', { downloadAptReportList: [] });

    orderService
      .aptReportList({ query })
      .then(response => {
        commit('setAptReportList', { downloadAptReportList: response.data.result });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  getStoreAvailability({ dispatch, commit }, { router, storeid, pincode }) {
    commit('setStoreAvailability', { storeAvailability: '' });
    dispatch('alert/clear', {}, { root: true });
    commit('setTempOrder', { tempOrder: {} });
    commit('startRequest');

    orderService
      .checkStoreAvailability({ storeid, pincode })
      .then(response => {
        commit('setStoreAvailability', { data: response.data });
        if (response.data) {
          router.push('/orderFormBoxV2/new');
        } else {
          commit('setErrorMessage', { errorMessage: `Service is not available for this pin code` });
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  checkStockAvailability({ dispatch, commit }, { params, order, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    orderService
      .checkStockAvailability({ params })
      .then(_response => {
        const { data } = _response;

        if (data[0].skunotfound.length) {
          const arr = {
            ...data[0],
            data: [
              data[0].data ? [...Object.values(data[0].data)] : [],
              ...data[0].skunotfound.map(i => {
                return {
                  sku: i,
                  available_qty: 0,
                  store_code: params.storecode,
                  requested_qty: params.items.find(item => item.product_id === i).qty || 0
                };
              })
            ].flat()
          };
          const abc = order.products.map(product => {
            const articleName = product.split(':::')[0];
            const requestedQty = product.split(':::')[1];
            const articleCode = product.split(':::')[2];
            const availableQty = arr.data.filter(item => item.sku === articleCode)[0]?.available_qty;

            return `${articleName}:::${requestedQty}:::${articleCode}:::${availableQty}`;
          });

          const xyz = {
            ...order,
            products: abc
          };
          commit('setTempOrder', { tempOrder: xyz });
          commit('requestFailed');
          router.push('/orderErrorPage');
        } else if (
          Object.values(data[0].data).length === params.items.length &&
          Object.values(data[0].data).every(
            ({ available_qty, requested_qty }) => Number(available_qty) >= Number(requested_qty)
          )
        ) {
          const params = {
            email: order.email,
            name: order.name,
            mobile: order.customerMobile,
            payment_method: 'cashondelivery',
            storeId: order.storeid?.toLowerCase(),
            address: {
              city: order.city,
              street: order.street,
              pincode: order.pincode,
              locality: order.locality,
              state: order.state,
              state_code: 'IN',
              phonenumber: order.customerMobile
            },
            items: order.products.map(product => {
              const product_id = product.split(':::')[2];
              const qty = product.split(':::')[1];
              return {
                product_id,
                qty
              };
            })
          };
          Vue.swal({
            title: 'Success',
            text: 'All Items are available in the inventory',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Place Order',
            showCancelButton: true,
            cancelButtonText: 'Cancel'
          }).then(result => {
            commit('requestFailed');

            if (result.value) {
              dispatch('postOrderMagento', { params, router, redirectUrl: '/online-order-list' });
            }
          });
        } else {
          const arr = {
            ...data[0],
            data: [
              data[0].data ? [...Object.values(data[0].data)] : [],
              ...data[0].skunotfound.map(i => {
                return {
                  sku: i,
                  available_qty: 0,
                  store_code: params.storecode,
                  requested_qty: params.items.find(item => item.product_id === i).qty || 0
                };
              })
            ].flat()
          };
          const abc = order.products.map(product => {
            const articleName = product.split(':::')[0];
            const requestedQty = product.split(':::')[1];
            const articleCode = product.split(':::')[2];
            const availableQty = arr.data.filter(item => item.sku === articleCode)[0]?.available_qty;

            return `${articleName}:::${requestedQty}:::${articleCode}:::${availableQty}`;
          });

          const xyz = {
            ...order,
            products: abc
          };
          commit('setTempOrder', { tempOrder: xyz });
          commit('requestFailed');
          router.push('/orderErrorPage');
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: 'Something went wrong. Please try again'
          },
          { root: true }
        );
      });
  },
  postOrderMagento({ dispatch, commit }, { params, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    orderService
      .postOrderMagento({ params })
      .then(_response => {
        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
        Vue.swal({
          title: 'Order created successfully',
          icon: 'success',
          type: 'success',
          confirmButtonText: 'Ok'
        });
        commit('finishRequest');
        commit('setTempOrder', { tempOrder: {} });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
        Vue.swal({
          title: 'Something went wrong, please try again later',
          text: `${e?.response?.data?.data[0]?.msg}`,
          icon: 'error',
          type: 'error',
          confirmButtonText: 'Ok'
        });
      });
  },

  getStateAndStateCode({ dispatch, commit }, { router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    orderService
      .getStateAndStateCode()
      .then(response => {
        commit('setStateCodeList', { stateCodeList: response.data });
        commit('finishRequest');
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },

  setErrorMessage({ commit }, { text }) {
    commit('setErrorMessage', { errorMessage: text });
  },
  setStorePinCode({ commit }, { storeid, pincode }) {
    commit('setStorePinCode', { storeIdPinCode: { storeid, pincode } });
  },
  updateTempOrder({ commit }, { order }) {
    commit('setTempOrder', { tempOrder: order });
  },
  ecommOrderList({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    orderService
      .ecommOrderList({ query })
      .then(response => {
        commit('setEcommOrders', { ecomOrderList: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  downloadData({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    orderService
      .ecommOrderList({ query })
      .then(response => {
        commit('setEcommOrdersOnline', {
          downloadOrdersListOnline: response.data.rows,
          pagination: response.data.pagination
        });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  getOneOrderMagento({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setTempOrder', { tempOrder: {} });
    commit('startRequest');

    orderService
      .getOneOrderMagento({ id })
      .then(response => {
        // commit('setOrder', { order: response.data });
        const abc = response.data;
        const order = {
          customerMobile: abc?.customerNumber,
          email: abc?.customerEmail,
          name: abc?.customerName,
          storeid: abc?.storeCode,
          city: abc?.address.city,
          locality: abc?.address?.locality,
          street: abc?.address.houseNo,
          pincode: abc?.address.pincode,
          state: abc?.address.state,
          // products: abc?.products
          quantity: abc?.products?.map(product => product.product_qty_ordered.slice(0, 2)),
          products: abc?.products?.map(product => {
            return {
              articleCode: product.product_id,
              id: product.product_id,
              productName: product.product_name
            };
          })
        };

        commit('setTempOrder', { tempOrder: order });
        commit('finishRequest');
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  findAddress({ dispatch, commit }, { search, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    orderService
      .findAddress(search)
      .then(response => {
        commit('setAddressSuggestions', { addressSuggestions: response.data });

        commit('finishRequest');
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  onlineCustomerData({ dispatch, commit }, { customerMobile, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setUserData', { userData: {} });
    orderService
      .onlineCustomerData({ customerMobile })
      .then(response => {
        commit('setUserData', { userData: response.data.user });
      })
      .catch(e => {
        dispatch(
          'alert/error',
          {
            position: 'center',
            title: '',
            text: 'No previous order found for this customer.',
            showType: 'toast'
          },
          { root: true }
        );
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  finishRequest(state) {
    state.loading = false;
  },
  setTempOrder(state, { tempOrder }) {
    state.tempOrder = tempOrder;
  },
  setUserData(state, { userData }) {
    state.userData = userData;
    state.loading = false;
  },
  setStoreAvailability(state, { data }) {
    if (data?.length > 0) {
      state.storeAvailability = 'available';
    }
    state.loading = false;
  },
  setStateCodeList(state, { stateCodeList }) {
    state.stateCodeList = stateCodeList?.map(state => {
      return {
        value: state.code,
        text: state.region_name
      };
    });
  },
  setAddressSuggestions(state, { addressSuggestions }) {
    state.addressSuggestions = addressSuggestions.map(address => {
      return {
        value: address.fullAddress,
        text: address.fullAddress,
        addressObj: address.address
      };
    });
  },
  setErrorMessage(state, { errorMessage }) {
    state.errorMessage = errorMessage;
  },
  setStorePinCode(state, { storeIdPinCode }) {
    state.storeIdPinCode = storeIdPinCode;
  },
  setEcommOrders(state, { ecomOrderList, pagination }) {
    let row = pagination ? pagination.first_row_no : 1;
    state.ecomOrderList = _.map(ecomOrderList, order => {
      const { _id } = order;

      const address = `${order?.address?.name} ${order.address.houseNo} ${order.address?.locality} ${order.address.city} ${order.address.state} ${order.address.stateCode} ${order.address.pincode}`;
      const formattedOrders = new EcommOrder({
        id: row,
        orderId: _id,
        customerNumber: order.customerNumber,
        name: order.customerName,
        customerEmail: order.customerEmail,
        createdAt: order.createdAt,
        deliveryType: order.deliveryType,
        address,
        description: order.description,
        orderIncrementId: order.orderIncrementId,
        orderTotal: order.orderTotal,
        paymentMode: order.paymentMode,
        quoteId: order.quoteId,
        updatedAt: order.updatedAt,
        storeid: order.storeCode,
        orderStatus: order.orderStatus,
        source: order.source
      });
      row -= 1;
      return formattedOrders;
    });
    state.pagination = pagination;
    state.loading = false;
  },

  setEcommOrdersOnline(state, { downloadOrdersListOnline, pagination }) {
    let row = pagination ? pagination.first_row_no : 1;
    state.downloadOrdersListOnline = _.map(downloadOrdersListOnline, order => {
      const { _id } = order;

      const address = `${order?.address?.name} ${order.address.houseNo} ${order.address?.locality} ${order.address.city} ${order.address.state} ${order.address.stateCode} ${order.address.pincode}`;
      const formattedOrders = new EcommOrder({
        id: row,
        orderId: _id,
        customerNumber: order.customerNumber,
        name: order.customerName,
        customerEmail: order.customerEmail,
        createdAt: order.createdAt,
        deliveryType: order.deliveryType,
        address,
        description: order.description,
        orderIncrementId: order.orderIncrementId,
        orderTotal: order.orderTotal,
        paymentMode: order.paymentMode,
        quoteId: order.quoteId,
        updatedAt: order.updatedAt,
        storeid: order.storeCode,
        orderStatus: order.orderStatus
      });
      row -= 1;
      return formattedOrders;
    });
    state.pagination = pagination;
    state.loading = false;
  },

  setOrders(state, { orders, pagination }) {
    let row = pagination ? pagination.first_row_no : 1;
    state.orders = _.map(orders, order => {
      const { _id } = order;
      const products = order.products.map(product => {
        if (product) {
          const name = product.split(':::').length > 1 ? product.split(':::')[0] : product;
          const quantity = product.split(':::').length > 1 ? product.split(':::')[1] : 1;
          return `${name} X ${quantity}`;
        }
        return null;
      });
      const formattedOrders = new Order({
        id: row,
        orderId: _id,
        customerMobile: order.customerMobile,
        name: order.name,
        address: order.address,
        description: order.description,
        source: order.source,
        products,
        storeid: order.storeid,
        dateCreated: order.createdAt,
        isAcknowledged: order.isAcknowledged,
        isDelivered: order.isDelivered,
        createdAt: order.createdAt ? order.createdAt : '',
        acknowledgedTime: order.acknowledgedTime,
        packagedTime: order.packagedTime,
        posPingTime: order.posPingTime,
        actualDeliveredTime: order.actualDeliveredTime,
        imageURL: order.imageURL ? order.imageURL : '',
        modeOfPayment: order.modeOfPayment,
        deliveryCode: order.deliveryCode
      });
      row -= 1;
      return formattedOrders;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setCustomerOrders(state, { customerOrders, user }) {
    let row = 1;
    state.customerOrders = _.map(customerOrders, order => {
      const { _id } = order;
      const products = order.products.map(product => {
        if (product) {
          const name = product.split(':::').length > 1 ? product.split(':::')[0] : product;
          const quantity = product.split(':::').length > 1 ? product.split(':::')[1] : 1;
          return `${name} X ${quantity}`;
        }
        return null;
      });

      const formattedOrders = new Order({
        id: row,
        orderId: _id,
        customerMobile: order.customerMobile,
        name: order.name,
        address: order.address,
        description: order.description,
        source: order.source,
        products,
        storeid: order.storeid,
        dateCreated: order.createdAt,
        isAcknowledged: order.isAcknowledged,
        isDelivered: order.isDelivered
      });
      row += 1;
      return formattedOrders;
    });
    if (user) {
      state.fetchedCustomerName = user.name;
      state.fetchedCustomerAddress = user.address;
    } else {
      state.fetchedCustomerName = null;
      state.fetchedCustomerAddress = null;
    }
    state.loading = false;
  },
  setOrder(state, { order }) {
    const { _id } = order;
    state.order = new Order({
      id: _id,
      orderId: _id,
      customerMobile: order.customerMobile,
      name: order.name,
      address: order.address,
      description: order.description,
      products: order.products,
      storeid: order.storeid,
      dateCreated: order.createdAt,
      createdAt: order.createdAt ? order.createdAt : '',
      acknowledgedTime: order.acknowledgedTime,
      packagedTime: order.packagedTime,
      posPingTime: order.posPingTime,
      posItems: order.posItems,
      actualDeliveredTime: order.actualDeliveredTime,
      imageURL: order.imageURL ? order.imageURL : '',
      deliveryPics: order.deliveryPics
    });
    state.loading = false;
  },
  setDownloadOrdersList(state, { downloadOrdersList }) {
    let row = 1;
    state.downloadOrdersList = _.map(downloadOrdersList, order => {
      const { _id } = order;
      const formattedOrders = new Order({
        id: row,
        orderId: _id,
        customerMobile: order.customerMobile,
        name: order.name,
        address: order.address,
        source: order.source,
        description: order.description,
        products: order.products,
        storeid: order.storeid,
        dateCreated: order.createdAt,
        dateUpdated: order.updatedAt,
        isAcknowledged: order.isAcknowledged,
        isDelivered: order.isDelivered,
        createdAt: order.createdAt ? order.createdAt : '',
        acknowledgedTime: order.acknowledgedTime,
        packagedTime: order.packagedTime,
        posPingTime: order.posPingTime,
        actualDeliveredTime: order.actualDeliveredTime,
        orderMode: order.orderMode,
        pinCode: order.pinCode,
        billNumber: order.billNumber ? order.billNumber : '',
        posItems: order.posItems.map(item => item.amount).reduce((a, b) => a + b, 0)
        // orderCreateLocation: order.orderCreateLocation,
        // orderDeliveredLocation: order.orderDeliveredLocation
        // UOM: order.posItems.map(item => item.UOM),
        // amount: order.posItems.map(item => item.amount),
        // disc: order.posItems.map(item => item.disc),
        // hsnCode: order.posItems.map(item => item.hsnCode),
        // itemCategory: order.posItems.map(item => item.itemCategory),
        // itemName: order.posItems.map(item => item.itemName),
        // mrp: order.posItems.map(item => item.mrp),
        // quantity: order.posItems.map(item => item.quantity)
      });
      row += 1;
      return formattedOrders;
    });
    state.loading = false;
  },
  setMessageRecordList(state, { downloadMessageRecordList }) {
    let row = 1;
    state.downloadMessageRecordList = _.map(downloadMessageRecordList, record => {
      const formattedData = new MessageRecords({
        id: row,
        type: record.type,
        createdAt: record.createdAt,
        isDelivered: record.isDelivered,
        medium: record.medium,
        mobile: record.mobile,
        referenceId: record.referenceId,
        storeid: record.storeid
      });
      row += 1;
      return formattedData;
    });
    state.loading = false;
  },
  setAptReportList(state, { downloadAptReportList }) {
    state.downloadAptReportList = downloadAptReportList;
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
